<template>
<div class="activity-detail">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="activity-detail-cont info">
    <div class="info-l">
      <img :src="info.img" />
      <p :class="info.status == 2 ? 'gray' : ''">{{ statusObj[info.status] }}</p>
    </div>
    <div class="info-r">
      <p class="info-r-t">{{ info.name }}</p>
      <p>开始时间：{{ info.start_time }}  - {{ info.end_time }}</p>
    </div>
  </div>
  <div class="activity-detail-cont mt20">
    <div class="top">
      <div class="search">
        <div>
          <div>搜索:</div>
          <el-input v-model="keyword"  clearable prefix-icon="el-icon-search" placeholder="输入手机号/ID搜索" style="width: 200px" @change="search"></el-input>
        </div>
        <div>
          <div>报名时间筛选:</div>
          <el-date-picker
              v-model="date"
              type="datetimerange"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd HH:mm"
              value-format="timestamp"
              @change="search"
          >
          </el-date-picker>
        </div>

        <div class="search-item">
          <div class="name">组织筛选：</div>
          <div class="cont">
            <Select v-model="searchSiteId"
                    ref="select"
                    remote
                    clearable
                    :remote-method="rankRemoteSite"
                    filterable  placeholder="请选择组织"
                    style="width: 200px"
                    size="large"
                    @on-change="search"
            >
              <Option v-for="item in searchSiteList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </div>

        </div>
        <div class="search-item">
          <div class="name">
            地域筛选:
          </div>
          <div class="cont">
            <el-cascader :options="cityList" v-model="cityId" :props="optionProps" clearable @change="search"></el-cascader>

          </div>
        </div>
      </div>
      <Button type="primary" @click="exportExcel">导出报名列表</Button>
    </div>
    <div class="table">
      <Table border :columns="columns" :data="dataList" :loading="loading"></Table>
    </div>
    <div class="page">
      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
    </div>
  </div>
</div>
</template>

<script>
import util from '@/utils/tools.js'
export default {
  name: "detail",
  data(){
    return {
      columns:[
        {
          title: '排序',
          key: 'rank'
        },
        {
          title: '用户ID',
          key: 'id',
          render: (h, params) => {
            return h('span', params.row.user && params.row.user.id);
          }
        },
        {
          title: '用户昵称',
          key: 'id',
          render: (h, params) => {
            return h('span', params.row.user && params.row.user.nickname);
          }
        },
        {
          title: '真实姓名',
          key: 'id',
          render: (h, params) => {
            return h('span', params.row.user && params.row.user.realname);
          }
        },
        {
          title: '所属组织',
          key: 'id',
          render: (h, params) => {
            return h('span', params.row.site && params.row.site.name);
          }
        },
        {
          title: '地区',
          key: 'id',
          render: (h, params) => {
            let arr = [];
            if(params.row.site.province){
              arr.push(params.row.site.province.name)
            }
            if(params.row.site.city){
              arr.push(params.row.site.city.name)
            }
            return h('div', {
            }, arr.length ? arr.join('-') : '');
          }
        },
        {
          title: '用户手机号',
          key: 'id',
          render: (h, params) => {
            return h('span', params.row.user.mobile);
          }
        },
        {
          title: '报名时间',
          key: 'id',
          render: (h, params) => {
            return h('span', util.timeFormatter(
                    new Date(+params.row.created_at * 1000), 'yyyy-MM-dd hh:mm'
                )
            );
          }
        },
      ],
      dataList:[],
      loading:false,
      total:0,
      page:1,
      keyword:'',
      date:[],
      dataId:'',
      info:{},
      statusObj:{},
      cityList:[],
      optionProps: {
        value: "id",
        label: "name",
        children: "children",
        checkStrictly:true
      },
      cityId: [],
      searchSiteList:[],
      searchSiteId:'',
      timer:null,
    }
  },
  created(){
    this.dataId = this.$route.query.id || '';
    this.getDetail();
    this.getList();
    this.getPlaceList();
    this.getSiteList();
  },
  methods:{
    getPlaceList(){ //获取地域
      this.api.site.placeList().then((res)=>{
        this.cityList = res.list;
      })
    },
    rankRemoteSite (query) {
      clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        this.getSiteList(query);
      },300)

    },
    getSiteList(data){
      let params = {
        pageSize:20,
        page:1,
        keyword:data,
      };
      this.api.site.siteList(params).then((res)=>{
        this.searchSiteList = res.list;
      })
    },
    getDetail(){
      let params = {
        id:this.dataId
      };
      this.api.activity.detail(params).then((res)=>{
        this.info = res.info;
        this.info.start_time = util.timeFormatter(
            new Date(+ this.info.start_time * 1000), 'yyyy-MM-dd hh:mm'
        );
        this.info.end_time = util.timeFormatter(
            new Date(+ this.info.end_time * 1000), 'yyyy-MM-dd hh:mm'
        )
        this.statusObj = res.statuses;
      })
    },
    changePage(page){
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize){
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList(){  //获取列表数据
      let params = {
        page:this.page,
        pageSize:this.pageSize,
        activity_id:this.dataId,
        keyword:this.keyword,
        province_id:this.cityId[0],
        city_id:this.cityId[1] || '',
        site_id:this.searchSiteId || '-1',
      };
      if(this.date && this.date.length){
        params.start_time = this.date[0]/1000;
        params.end_time = this.date[1]/1000;
      }
      this.api.activity.activityApplyList(params).then((res)=>{
        this.dataList = res.list;
        this.total = Number(res.count);
      })
    },
    search(){
      this.page = 1;
      this.getList();
    },
    exportExcel(){
      let params = {
        activity_id:this.dataId,
        keyword:this.keyword,
        province_id:this.cityId[0],
        city_id:this.cityId[1] || '',
        site_id:this.searchSiteId || '-1',
      };
      if(this.date.length){
        params.start_time = this.date[0]/1000;
        params.end_time = this.date[1]/1000;
      }
      this.api.activity.exportActivityApply(params).then((res)=>{
        let uploadParams = {
          file:res.filepath
        };
        this.api.codekey.uploadfileDownload(uploadParams).then((res)=>{
          util.downLoad(res);
          this.exportNamesModal = false;
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.activity-detail{
  margin: 20px;
  font-size: 14px;
  .activity-detail-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
    font-size: 14px;


  }
  .info{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .info-l{
      margin-right: 20px;
      width: 160px;
      height: 100px;
      position: relative;

      >img{
        width: 100%;
        height: 100%;
        border-radius: 6px;
      }
      >p{
        position: absolute;
        top: 0;
        left: 0;
        width: 60px;
        height: 30px;
        border-radius: 6px;
        text-align: center;
        line-height: 30px;
        background-color: #4E83FF;
        font-size: 12px;
        color: #FFFFFF;
      }
      .gray{
        background-color:#F4F4F4;
        color:#999999;
      }
    }
    .info-r{
      .info-r-t{
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .search{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
      >div{
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        >div{

          margin-right: 10px;
        }
        >div:first-child{
          width: 100px;
          text-align: right;
        }
      }
    }
  }
  .table{
    margin-top: 20px;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
</style>
